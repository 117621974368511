import * as React from "react";
import { Theme, useTheme } from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useState, useEffect } from "react";
import { InputContainer } from "../admin/addAdminModal/AddAdminModal";

const ITEM_HEIGHT = 40;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

const adminRole = [
	{
		name: "Admin Manager",
		value: "adminManager",
	},
	{
		name: "Vendor Manager",
		value: "artisanManager",
	},
	{
		name: "Partnership Manager",
		value: "occupationalManager",
	},
	{
		name: "Booking Manager",
		value: "bookingManager",
	},
	{
		name: "Customer Manager",
		value: "userManager",
	},
];

function getStyles(name: string, selectedRoles: string[], theme: Theme) {
	return {
		fontWeight:
			selectedRoles.indexOf(name) === -1
				? theme.typography.fontWeightRegular
				: theme.typography.fontWeightMedium,
	};
}

export default function MultipleSelect({
	setRoles,
	roleError,
	defaultRoles,
}: {
	setRoles: Function;
	roleError: string;
	defaultRoles?: string[];
}) {
	const theme = useTheme();
	const [adminRoles, setAdminRoles] = useState<string[]>([]);

	// Preselect roles if defaultRoles is provided
	useEffect(() => {
		if (defaultRoles && defaultRoles.length > 0) {
			setAdminRoles(defaultRoles);
		}
	}, [defaultRoles]);

	const handleChange = (event: SelectChangeEvent<string[]>) => {
		const {
			target: { value },
		} = event;

		const selectedRoles = typeof value === "string" ? value.split(",") : value;

		setAdminRoles(selectedRoles);
		setRoles((prevState: any) => ({
			...prevState,
			role: selectedRoles,
			roleError: "",
		}));
	};

	return (
		<>
			<InputContainer>
				<label htmlFor='Role'>Role</label>
				<FormControl fullWidth size='small'>
					<Select
						labelId='demo-multiple-name-label'
						id='demo-multiple-name'
						multiple
						value={adminRoles}
						onChange={handleChange}
						style={{ padding: "0px auto" }}
						MenuProps={MenuProps}
						required
					>
						{adminRole.map((role) => (
							<MenuItem
								key={role.name}
								value={role.value}
								style={getStyles(role.value, adminRoles, theme)}
							>
								{role.name}
							</MenuItem>
						))}
					</Select>
				</FormControl>
				{roleError && <h6 className='validation_error'>{roleError}</h6>}
			</InputContainer>
		</>
	);
}
