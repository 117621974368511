import * as React from "react";
import { Theme, useTheme } from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

const ITEM_HEIGHT = 40;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

const countries = [
	{
		name: "Nigeria",
		value: "NG",
	},
	{
		name: "Ghana",
		value: "GH",
	},
	{
		name: "Global",
		value: "GB",
	},
];

export default function CountrySelect({
	setCountry,
	countryError,
	countryData,
}: {
	setCountry: Function;
	countryError: string;
	countryData?: string;
}) {
	const theme = useTheme();
	const [countryName, setCountryName] = React.useState<string>("");

	React.useEffect(() => {
		if (countryData) {
			setCountryName(countryData);
		}
	}, [countryData]);

	const handleChange = (event: SelectChangeEvent<string>) => {
		const selectedCountry = event.target.value;
		const country = countries.find((c) => c.name === selectedCountry);
		setCountryName(selectedCountry);
		setCountry((prevState: any) => ({
			...prevState,
			country: country?.value,
			countryError: "",
		}));
	};

	return (
		<FormControl fullWidth error={!!countryError}>
			<label htmlFor='Role'>Country</label>
			<Select
				value={countryName}
				onChange={handleChange}
				MenuProps={MenuProps}
				displayEmpty
			>
				<MenuItem value='' disabled>
					Select a country
				</MenuItem>
				{countries.map((country) => (
					<MenuItem
						key={country.value}
						value={country.name} // Assuming `countryData` holds the country name
						style={{
							fontWeight:
								countryName === country.name
									? theme.typography.fontWeightMedium
									: theme.typography.fontWeightRegular,
						}}
					>
						{country.name}
					</MenuItem>
				))}
			</Select>
			{countryError && (
				<div style={{ color: "red", marginTop: 8 }}>{countryError}</div>
			)}
		</FormControl>
	);
}
