import styled from "styled-components";
import { Flex, Table } from "src/components/ui";
import { useNavigate } from "react-router-dom";
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { usePagination } from "src/hooks";
import { ReferralListProps } from "src/pages/dashboard/dashboardTypes";
import { PaginationProps } from "../ui/Pagination";

const ReferralTableContainer = styled.div`
	background-color: ${(props) => props.theme.colors.white};
	margin: 3rem 0;
	border-radius: 16px;
	padding: 20px 0;
	.heading {
		color: ${(props) => props.theme.colors.text_01};
		padding: 0 20px;

		.title {
			font-weight: 600;
			font-size: 22px;
			line-height: 32px;
		}
		.info {
			font-weight: 500;
			font-size: 14px;
			line-height: 24px;
		}
	}
`;

const ReferralTableHeaders = [
	{
		title: "Referrer",
		render: (row: ReferralListProps) => (
			<Flex gap='10px' align='center'>
				<img
					style={{ width: 40, height: 40, borderRadius: "50%" }}
					src={row.referrer.display_picture}
					alt=''
				/>{" "}
				{row.referrer.first_name} {row.referrer.last_name}
			</Flex>
		),
	},
	{
		title: "Total Referred",
		render: (row: ReferralListProps) => `${row.numberOfReferredUsers}`,
	},
	{
		title: "Referral Code",
		render: (row: ReferralListProps) => `${row.referralCode}`,
	},
];

export const ReferralTable: FC<{
	rows: ReferralListProps[];
	pgn: number;
	setpgn: Dispatch<SetStateAction<number>>;
	paginationData: PaginationProps;
}> = ({ rows, pgn, setpgn, paginationData }) => {
	const navigate = useNavigate();
	const handleNavigate = (row: ReferralListProps) => {
		if (row.referrer.user_type === "User") {
			navigate(`/customers/${row.referrer.id}?tabStatus=all`);
		} else {
			navigate(`/vendors/${row.referrer.id}?tabStatus=all`);
		}
	};
	const { page, limit, Pagination } = usePagination(
		{
			page: pgn,
			limit: 10,
			total: rows.length,
			setPageNo: setpgn,
		},
		paginationData
	);

	const [paginatedRows, setPaginatedRows] = useState<ReferralListProps[]>([]);

	useEffect(() => {
		// setPaginatedRows(rows.slice((page - 1) * limit, page * limit));
		setPaginatedRows(rows);
	}, [page, limit, rows]);

	return (
		<ReferralTableContainer>
			<div className='heading'>
				<p className='title'>Referral History</p>
			</div>
			{rows.length > 0 ? (
				<Table
					rows={paginatedRows}
					headers={ReferralTableHeaders}
					showHead={true}
					onRowClick={handleNavigate}
				/>
			) : (
				<h5 className='font-bold text-center py-8 italic text-2xl'>
					No recent Referrals
				</h5>
			)}
			<Pagination />
		</ReferralTableContainer>
	);
};

export default ReferralTable;
