import { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { DashboardLayout } from "src/components/dashboard";
import ReferralTable from "src/components/dashboard/referralTable";
import { DashboardService } from "src/service/Dashboard";
import { ReferralListProps } from "../dashboard/dashboardTypes";
import { PaginationProps } from "src/components/ui/Pagination";
import AppModal from "src/components/ui/widget/Modal/Modal";
import ModalContent from "src/components/common/ModalContent";
import { useLoading } from "src/hooks";
import userServices from "src/service/userServices";
import adminServiceApi from "src/utils/AdminServiceApi";
import { AdminServices } from "src/service/AdminServices";

const RerralsHistoryPage = () => {
	const [allReferrals, setAllReferrals] = useState<ReferralListProps[]>([]);
	const [showExportModal, setShowExportModal] = useState(false);
	const [paginationData, setPaginationData] = useState<PaginationProps>({
		page: 0,
		pageSize: 0,
		total: 0,
		totalPages: 0,
	});
	const [pageNo, setPageNo] = useState(0);
	const {
		loading: exportingData,
		startLoading: startExportingData,
		stopLoading: stopExportingData,
	} = useLoading(false);

	const getAllReferrals = async () => {
		try {
			const referrals = await DashboardService.Referrals(pageNo);
			setAllReferrals(referrals?.data?.payload?.data);
			setPaginationData(referrals?.data?.payload?.pagination);
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		getAllReferrals();
	}, [pageNo]);

	const dumpReferralData = () => {
		startExportingData();
		AdminServices.exportReferral()
			.then((res) => {
				stopExportingData();
				toast.success(res?.data?.message);
				console.log(res);
				setShowExportModal(false);
				window.location.href = res?.data?.payload?.data?.fileUrl;
			})
			.catch((err) => {
				console.log("error");
				stopExportingData();
				toast.error(
					err?.response?.data?.error?.message ?? "Error exporting data"
				);
			});
	};

	return (
		<DashboardLayout
			pageTitle='Referrals Leaderboard'
			rhsHeading={
				<button
					onClick={() => setShowExportModal(true)}
					className='bg-primary text-white p-3 rounded-md px-6'
				>
					Export
				</button>
			}
		>
			<ToastContainer />
			<ReferralTable
				pgn={pageNo}
				setpgn={setPageNo}
				rows={allReferrals}
				paginationData={paginationData}
			/>
			<AppModal
				open={showExportModal}
				onClose={() => setShowExportModal(false)}
				content={
					<ModalContent
						content1='Export Reeferral Data'
						content2='Are you sure you want to export referral data?'
						btnAction={dumpReferralData}
						linkContent={"Yes, Please"}
						onClick={() => setShowExportModal(false)}
						loading={exportingData}
					/>
				}
			/>
		</DashboardLayout>
	);
};

export default RerralsHistoryPage;
