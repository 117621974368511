import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { DashboardLayout } from "src/components/dashboard";
import { UsersTable, UsersStaleTable } from "src/components/users";
import { Input } from "src/components/inputs";
import { Button, ButtonClass, Flex } from "src/components/ui";
import searchIcon from "src/assets/images/input/searchIcon.svg";
import userServices from "src/service/userServices";
import { useLoading, useExportData } from "src/hooks";
import { Loader } from "src/components/common";
import { toast, ToastContainer } from "react-toastify";
import { theme } from "src/styles/Theme";
import { SendNotificationModal } from "src/components/users";
import AppModal from "src/components/ui/widget/Modal/Modal";
import ModalContent from "src/components/common/ModalContent";

const UsersContainer = styled.div``;

interface Props {
	handleChange: (e: any) => void;
	handleOpen: () => void;
	handleStale: (e: any) => void;
	handleActive: (e: any) => void;
	handleExport: () => void;
	staleTableBtn: boolean;
}

const usersInitialState: UsersListTypes = {
	display_picture: "",
	email: "",
	first_name: "",
	gender: "",
	last_name: "",
	_id: "",
	tier: "",
	phone: "",
	deleted: false,
	createdAt: "",
};

export default function Users() {
	const [searchField, setSearchField] = useState("");
	const [usersList, setUsersList] = useState<UsersListTypes[]>([]);
	const [staleUsersList, setStaleUsersList] = useState<UsersListTypes[]>([]);
	const [openNotifModal, setOpenNotifModal] = useState(false);
	const [showExportModal, setShowExportModal] = useState(false);
	const [showStaleAccount, setShowStaleAccount] = useState(false);
	const [staleTableBtn, setStaleTableBtn] = useState(true);

	const handleOpen = () => setOpenNotifModal(true);
	const handleClose = () => setOpenNotifModal(false);

	const {
		loading: fetchingUsers,
		startLoading: startFetchingUsers,
		stopLoading: stopFetchingUsers,
	} = useLoading(false);

	const {
		loading: fetchingStaleUsers,
		startLoading: startFetchingStaleUsers,
		stopLoading: stopFetchingStaleUsers,
	} = useLoading(false);

	const {
		loading: exportingData,
		startLoading: startExportingData,
		stopLoading: stopExportingData,
	} = useLoading(false);

	const fetchUsers = () => {
		startFetchingUsers();
		userServices
			.getUsers()
			.then((res) => {
				console.log(res?.data?.payload?.data);
				setUsersList(res?.data?.payload?.data || usersInitialState);
			})
			.catch((err) => {
				console.log(err?.response?.data?.error?.message);
				toast.error(err?.response?.data?.error?.message);
			})
			.finally(() => stopFetchingUsers());
	};

	useEffect(() => {
		fetchUsers();
		// fetchStaleUsers();
		console.log(staleUsersList, "stale user list");
	}, []);

	const fetchStaleUsers = () => {
		startFetchingStaleUsers();
		userServices
			.getStaleUsers()
			.then((res) => {
				// console.log(res?.data?.payload?.data);
				setStaleUsersList(res?.data?.payload?.data?.users);
			})
			.catch((err) => {
				console.log(err?.response?.data?.error?.message);
				// toast.error(err?.response?.data?.error?.message);
			})
			.finally(() => stopFetchingStaleUsers());
	};

	const handleStale = () => {
		setShowStaleAccount(true);
		setStaleTableBtn(false);
		fetchStaleUsers();
	};

	const handleActive = () => {
		setShowStaleAccount(false);
		setStaleTableBtn(true);
		fetchUsers();
	};

	const filteredData = usersList.filter((data) => {
		return (
			data?.first_name?.toLowerCase().includes(searchField.toLowerCase()) ||
			data?.last_name?.toLowerCase().includes(searchField.toLowerCase()) ||
			data?.email?.toLowerCase().includes(searchField.toLowerCase()) ||
			data?.phone?.toLowerCase().includes(searchField.toLowerCase())
		);
	});

	const filteredStaleData = staleUsersList.filter((data) => {
		return (
			data?.first_name?.toLowerCase().includes(searchField.toLowerCase()) ||
			data?.last_name?.toLowerCase().includes(searchField.toLowerCase()) ||
			data?.email?.toLowerCase().includes(searchField.toLowerCase()) ||
			data?.phone?.toLowerCase().includes(searchField.toLowerCase())
		);
	});

	const handleChange = (e: any) => {
		setSearchField(e.target.value);
	};

	useEffect(() => {
		document.title = "Customer's Page";
	}, []);

	//Exporting data to csv format
	const usersHeaders = ["Name", "Email", "Gender", "Phone"];
	const DATA_TO_EXPORT = usersList.map((item) => {
		return {
			first_name: `${item?.first_name} ${item.last_name}`,
			email: `${item?.email}`,
			gender: `${item?.gender}`,
			phone: `${item?.phone}`,
		};
	});

	const dumpUserData = () => {
		startExportingData();
		userServices
			.DumpCustomerData()
			.then((res) => {
				stopExportingData();
				toast.success(res?.data?.message);
				console.log(res);
				setShowExportModal(false);
				window.location.href = res?.data?.payload?.data?.fileUrl;
			})
			.catch((err) => {
				console.log("error");
			});
	};

	return (
		<DashboardLayout
			pageTitle='Customers'
			rhsHeading={
				<RhsHeading
					handleChange={handleChange}
					handleOpen={handleOpen}
					handleStale={handleStale}
					handleActive={handleActive}
					staleTableBtn={staleTableBtn}
					handleExport={() => setShowExportModal(!showExportModal)}
				/>
			}
		>
			<ToastContainer />
			<UsersContainer>
				{fetchingUsers || fetchingStaleUsers ? (
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							marginTop: "100px",
						}}
					>
						<Loader>loading...</Loader>{" "}
					</div>
				) : showStaleAccount ? (
					<UsersStaleTable rows={filteredStaleData} />
				) : filteredData.length > 0 ? (
					<UsersTable rows={filteredData} />
				) : (
					<p className='table-entry-status'>No Customer Found</p>
				)}
			</UsersContainer>
			<SendNotificationModal
				open={openNotifModal}
				userId={[""]}
				handleClose={handleClose}
				artisan={false}
			/>
			<AppModal
				open={showExportModal}
				onClose={() => setShowExportModal(false)}
				content={
					<ModalContent
						content1='Export Customers Data'
						content2='Are you sure you want to export customers data?'
						btnAction={dumpUserData}
						linkContent={"Yes, Please"}
						onClick={() => setShowExportModal(false)}
						loading={exportingData}
					/>
				}
			/>
		</DashboardLayout>
	);
}

export const RhsHeading: React.FC<Props> = ({
	handleChange,
	handleOpen,
	handleExport,
	handleStale,
	handleActive,
	staleTableBtn,
}) => (
	<Flex wrap='wrap'>
		<Button
			onClick={handleExport}
			classes={[ButtonClass.SOLID, ButtonClass.WITH_ICON]}
			style={{
				backgroundColor: theme.colors.purple,
				height: "48px",
				minWidth: "145px",
			}}
		>
			<span>Export</span>
		</Button>
		<Button
			onClick={handleOpen}
			classes={[ButtonClass.SOLID, ButtonClass.WITH_ICON]}
			style={{
				backgroundColor: theme.colors.purple,
				height: "48px",
				width: "max-content",
			}}
		>
			<span>Notify all customers</span>
		</Button>
		<Input
			icon={<img src={searchIcon} alt='searchIcon' />}
			type='search'
			placeholder='Search'
			handleChange={handleChange}
		/>
		<Button
			onClick={handleActive}
			classes={[ButtonClass.SOLID, ButtonClass.WITH_ICON]}
			style={{
				backgroundColor: "#eb5656",
				height: "48px",
				width: "max-content",
			}}
			disabled={staleTableBtn}
		>
			<span>Active Users</span>
		</Button>

		<Button
			onClick={handleStale}
			classes={[ButtonClass.SOLID, ButtonClass.WITH_ICON]}
			style={{
				backgroundColor: "#210639",
				height: "48px",
				width: "max-content",
			}}
			disabled={!staleTableBtn}
		>
			<span>Stale Users</span>
		</Button>
	</Flex>
);
