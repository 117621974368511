import { FC, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { TextField } from "src/components/ui/form/FormComponent";
import { auth } from "src/store/Auth";
import Button from "src/components/ui/widget/Button";
import { toast } from "react-toastify";
import KycApprovedModal from "src/components/kyc/kycModals/KycApprovedModal";
import RejectionModal from "src/components/kyc/kycModals/RejectionModal";
import { useLoading } from "src/hooks";
import VerificationService from "src/service/VerifyService";
import AppModal from "src/components/ui/widget/Modal/Modal";
import ModalContent from "src/components/common/ModalContent";

type SocialType = {
	others: string;
	linkedin: string;
	facebook: string;
	twitter: string;
	instagram: string;
};
const Socials: FC<{
	id: string;
	who: string;
	fetchData: Function;
	verifyData: { [key: string]: any };
}> = ({ id, verifyData, who, fetchData }) => {
	const { formState, handleSubmit, register, control, setValue } =
		useForm<SocialType>({
			mode: "onChange",
		});

	const [open, setOpen] = useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => {
		setOpen(false);
		fetchData(id);
	};
	const [openReject, setOpenReject] = useState(false);
	const handleRejectOpen = () => setOpenReject(true);
	const handleRejectClose = () => {
		setOpenReject(false);
		fetchData(id);
	};
	const [rejectionReason, setRejectionReason] = useState("");
	const [showModal, setShowModal] = useState(false);

	//Check if Logged Admin is an Occupational Manager
	const { authUser } = auth.use();

	const [isOccupManager, setIsOccupManager] = useState(false);

	useEffect(() => {
		//Set State to true is Occupational Manager is logged in
		if (authUser?.role?.includes("occupationalManager")) {
			setIsOccupManager(true);
		}
	}, [authUser]);

	const INPUTVAL = !!(
		verifyData?.verification?.social_media?.twitter ||
		verifyData?.verification?.social_media?.facebook ||
		verifyData?.verification?.social_media?.linkedin ||
		verifyData?.verification?.social_media?.instagram ||
		verifyData?.verification?.social_media?.others
	);

	// console.log(verifyData);

	useEffect(() => {
		setValue("twitter", verifyData?.verification?.social_media?.twitter || "");
		setValue(
			"facebook",
			verifyData?.verification?.social_media?.facebook || ""
		);
		setValue(
			"linkedin",
			verifyData?.verification?.social_media?.linkedin || ""
		);
		setValue(
			"instagram",
			verifyData?.verification?.social_media?.instagram || ""
		);
		setValue("others", verifyData?.verification?.social_media?.instagram || "");
		setValue("others", verifyData?.verification?.social_media?.others || "");
	}, [
		setValue,
		verifyData?.verification?.social_medial?.twitter,
		verifyData?.verification?.social_media?.facebook,
		verifyData?.verification?.social_media?.linkedin,
		verifyData?.verification?.social_media?.instagram,
		verifyData?.verification?.social_media?.others,
	]);

	const {
		loading: rejectingKyc,
		startLoading: startRejectingKyc,
		stopLoading: stopRejectingKyc,
	} = useLoading(false);

	const handleRejectKyc = handleSubmit(() => {
		if (rejectingKyc) {
			return;
		}
		startRejectingKyc();
		id &&
			VerificationService.approveRejectVerification(
				{ reason: rejectionReason },
				id,
				"reject",
				"social_media",
				who
			)
				.then((res) => {
					// console.log(res.data);
					toast.success(res?.data?.message);
					// handleRejectClose();
				})
				.catch((err) => {
					toast.error(err.response.data.error.message);
				})
				.finally(() => {
					stopRejectingKyc();
					handleRejectClose();
					// navigate('/kyc');
				});
	});

	const {
		loading: approvingKyc,
		startLoading: startApprovingKyc,
		stopLoading: stopApprovingKyc,
	} = useLoading(false);

	const onSubmit: SubmitHandler<SocialType> = () => {
		// console.log(data);
		if (approvingKyc || !INPUTVAL) {
			return;
		}
		startApprovingKyc();
		VerificationService.approveRejectVerification(
			{ reason: "" },
			id,
			"approve",
			"social_media",
			who
		)
			.then((res) => {
				// console.log(res.data);
				setShowModal(false);
				toast.success(res?.data?.message);
				setTimeout(() => handleOpen(), 1000);
			})
			.catch((err) => {
				toast.error(err.response.data.error.message);
			})
			.finally(() => {
				stopApprovingKyc();
			});
	};

	// console.log(INPUTVAL);

	return (
		<>
			<form className='w-[60%] pb-[29px] pt-[74px]'>
				<div className='flex flex-col justify-between h-full'>
					<div className='p-[34px] w-full max-w-[530px] mx-auto'>
						<h3 className='font-medium text-2xl'>Social Media Linking</h3>
						<div className='mt-10'>
							{/* <TextField
                label={"Twitter"}
                type="text"
                disabled={true}
                control={control}
                error={formState.errors.twitter}
                {...register("twitter", { required: false })}
              /> */}
							<TextField
								label={"Facebook"}
								type='text'
								disabled={true}
								control={control}
								error={formState.errors.facebook}
								{...register("facebook", { required: false })}
							/>
							{/* <TextField
                label={"Linkedin"}
                type="text"
                disabled={true}
                control={control}
                error={formState.errors.linkedin}
                {...register("linkedin", { required: false })}
              /> */}
							<TextField
								label={"Instagram"}
								type='text'
								disabled={true}
								control={control}
								error={formState.errors.instagram}
								{...register("instagram", { required: false })}
							/>
							<TextField
								label={"Other websites or Social media"}
								type='text'
								disabled={true}
								control={control}
								error={formState.errors.others}
								{...register("others", { required: false })}
							/>
						</div>
					</div>
					{verifyData?.artisan?.verified?.social_meida ||
					verifyData?.user?.verified?.social_meida ? (
						<div className='flex gap-2 items-center justify-center'>
							<img src='/svgs/verified.svg' alt='' className='w-9 h-9' />
							<p className='text-[#667085] font-semibold'>
								Validation Confirmed
							</p>
						</div>
					) : INPUTVAL ? (
						<div className='flex gap-6 justify-center'>
							<Button
								onClick={(e?: any) => {
									e.preventDefault();
									setShowModal(true);
								}}
								disabled={approvingKyc}
								classes={`bg-[#7607BD] w-[100px] py-2 flex justify-center items-center gap-2 text-white ${
									INPUTVAL ? "" : "cursor-not-allowed"
								} ${isOccupManager ? "!hidden" : ""}`}
							>
								Accept
							</Button>
							<Button
								onClick={(e?: any) => {
									e.preventDefault();
									if (!INPUTVAL) {
										return;
									} else {
										handleRejectOpen();
									}
								}}
								classes={`border border-[#D92D20] w-[100px] py-2 flex justify-center items-center gap-2 text-[#D92D20] ${
									INPUTVAL ? "" : "cursor-not-allowed"
								} ${isOccupManager ? "!hidden" : ""}`}
							>
								Reject
							</Button>
						</div>
					) : (
						""
					)}
				</div>
				<KycApprovedModal
					open={open}
					handleClose={handleClose}
					name={`${
						verifyData?.artisan?.first_name || verifyData?.user?.first_name
					} ${verifyData?.artisan?.last_name || verifyData?.user?.last_name}`}
				/>
				<RejectionModal
					open={openReject}
					handleRejectKyc={handleRejectKyc}
					handleClose={handleRejectClose}
					rejectionReason={rejectionReason}
					setRejectionReason={setRejectionReason}
					rejectingKyc={rejectingKyc}
				/>
			</form>

			<AppModal
				open={showModal}
				onClose={() => setShowModal(false)}
				content={
					<ModalContent
						content1='Accept Social Media Links'
						content2='Are you sure you want to accept socials?'
						btnAction={handleSubmit(onSubmit)}
						linkContent={"Yes, Please"}
						loading={approvingKyc}
						onClick={() => setShowModal(false)}
					/>
				}
			/>
		</>
	);
};

export default Socials;
