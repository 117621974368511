import { adminServiceApi } from "src/utils/AdminServiceApi";

export const AdminServices = {
	getAllAdmins: async () => {
		return adminServiceApi().get("admin/v1/");
	},
	getAdmin: async (admin_id: string) => {
		return adminServiceApi().get(`admin/v1/${admin_id}`);
	},
	DumpActivityLog: async () => {
		return adminServiceApi().get(`/admin/v1/activity-logs`);
	},
	addAdmin: async (data: any) => {
		return adminServiceApi().post(`admin/v1/`, data);
	},
	editadmin: async (data: any, id: string) => {
		return adminServiceApi().put(`admin/v1/${id}`, data);
	},
	deleteadmin: async (id: string) => {
		return adminServiceApi().delete(`admin/v1/${id}`, {});
	},
	sendMail: async (data: any) => {
		return adminServiceApi().post(`admin/v1/notification/mail`, data);
	},
	pushNotification: async (data: any) => {
		return adminServiceApi().post(`admin/v1/notification/pn`, data);
	},
	suspendAdmin: async (admin_id: string, action: string) => {
		return adminServiceApi().put(`admin/v1/${admin_id}/${action}`);
	},
	exportReferral: async () => {
		return adminServiceApi().get(`admin/v1/export/referral/leaderboard`);
	},
};
